interface SimpleServerError {
    code: number;
    message: string;
}

interface FormErrorData extends SimpleServerError {
    data: ErrorsData;
}

interface ErrorsData {
    errors: AttributeErrorData[];
}

interface AttributeErrorData {
    attribute: string;
    message: string;
}

interface FormValidationServerErrorWithData {
    response: FormErrorData;
}

export const APPLICATION_VALIDATION_FAILED = 24512;

export function isApplicationFormValidationError(e: unknown): e is FormValidationServerErrorWithData {
    const errorResponse = (e as FormValidationServerErrorWithData)?.response;

    return APPLICATION_VALIDATION_FAILED === errorResponse?.code && !!errorResponse?.data?.errors?.length;
}
