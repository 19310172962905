import {Outlet} from "react-router-dom";

import {HeaderContextProvider} from "context/header-context";

import Footer from "./Footer";
import Header from "./Header";

const VehicleListingsLayout = () => {
    return (
        <HeaderContextProvider>
            <div className="flex h-[calc(100dvh)] w-full flex-col justify-between">
                <Header />
                <div className="bg-neutral-25 flex-1">
                    <div className="flex h-full text-base leading-normal">
                        <div className="flex h-full w-full flex-col">
                            <Outlet />
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </HeaderContextProvider>
    );
};

export default VehicleListingsLayout;
