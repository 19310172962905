import {LocalStorageKeys, localStorageService} from "common/services/LocalStorageService";
import {v4} from "uuid";
import {CommonBrowserInfo} from "@fleet/common/services/CommonBrowserInfo";

class BrowserInfo extends CommonBrowserInfo {
    constructor() {
        super(localStorageService.getItem, localStorageService.setItem);
    }

    public getVisitorId() {
        const visitorIdFromLocalStorage = localStorageService.getItem(LocalStorageKeys.VISITOR_ID);
        if (visitorIdFromLocalStorage) {
            return visitorIdFromLocalStorage;
        }

        const visitorId = v4();
        localStorageService.setItem(LocalStorageKeys.VISITOR_ID, visitorId);
        return visitorId;
    }
}

export const browserInfo = new BrowserInfo();
