import {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";

import {useAbsolutePath} from "common/hooks/useAbsolutePath";
import {FetchStatus, useFetch, useFetchNoAuth} from "common/hooks/useFetch";
import {useI18n} from "common/hooks/useI18n";
import {ApplicationContextProvider} from "common/providers/appStateProvider";
import {VehicleMarketplaceNoAuthApiClient} from "common/services/api/api_clients/noAuthApiClient";
import {Api} from "common/services/api/apiProvider";
import TableSearchField from "@fleet/common/components/table/TableSearchField";

import {VehicleMarketplaceNoAuthService, VehicleMarketplaceService} from "@bolteu/bolt-server-api-vehicle-marketplace";
import {Select, SelectOption} from "@bolteu/kalep-react";

import ListingsTableBody, {TableListing} from "./ListingsTableBody";

interface Props {
    selectedCity: SelectOption | null;
    setSelectedCity: (city: SelectOption) => void;
}

const fetchAuthenticatedFunction = (api: Api, body: VehicleMarketplaceService.SearchPartnerListingsRequest) =>
    api.vehicleMarketplace.partnerDriverWebVehicleListingSearch(body);
const fetchNonAuthenticatedFunction = (body: VehicleMarketplaceNoAuthService.SearchListingsRequest) =>
    VehicleMarketplaceNoAuthApiClient.webVehicleListingSearch(body);

const ListingsTable = ({selectedCity, setSelectedCity}: Props) => {
    const {i18n} = useI18n();
    const navigate = useNavigate();
    const {getHomePath} = useAbsolutePath();
    const [searchParams] = useSearchParams();
    const referrerUrl = searchParams.get("referrer_url") ?? undefined;

    const appState = useContext(ApplicationContextProvider);

    const [searchTerm, setSearchTerm] = useState<string>("");
    const {cities} = appState;

    const availableCities = useMemo((): SelectOption[] => {
        return (cities || []).map((city) => ({value: city.id, title: city.name}));
    }, [cities]);

    const fetchLimit = 1000;

    const {
        fetch: authenticatedFetch,
        data: authListings,
        status: authLoadStatus,
    } = useFetch(fetchAuthenticatedFunction);
    const {
        fetch: nonAuthenticatedFetch,
        data: noAuthListings,
        status: noAuthLoadStatus,
    } = useFetchNoAuth(fetchNonAuthenticatedFunction);

    useEffect(() => {
        if (appState.isAuthenticated && authenticatedFetch && selectedCity?.value) {
            authenticatedFetch({
                city_id: Number(selectedCity.value),
                query: searchTerm,
                referrer_url: referrerUrl,
                limit: fetchLimit,
            });
        } else if (!appState.isAuthenticated && nonAuthenticatedFetch && selectedCity?.value) {
            nonAuthenticatedFetch({
                city_id: Number(selectedCity.value),
                query: searchTerm,
                referrer_url: referrerUrl,
                limit: fetchLimit,
            });
        }
    }, [
        authenticatedFetch,
        nonAuthenticatedFetch,
        appState.isAuthenticated,
        selectedCity?.value,
        searchTerm,
        appState.isAppReady,
        referrerUrl,
    ]);

    const onCityChange = useCallback(
        (selected: SelectOption | SelectOption[] | null) => {
            if (selected) {
                const option = selected as SelectOption;
                const city = (cities || []).find((c) => c.id === option.value);
                setSelectedCity(option);
                if (city) {
                    navigate(getHomePath());
                }
            }
        },
        [cities, setSelectedCity, navigate, getHomePath],
    );

    const onSearchChange = useCallback((search: string) => {
        setSearchTerm(search);
    }, []);

    const listings: TableListing[] = useMemo(() => {
        if (appState.isAuthenticated) {
            return (authListings?.rows || []).map((listing) => ({
                ...listing,
                is_user_applied: listing.is_user_applied ?? false,
            }));
        }

        return (noAuthListings?.rows || []).map((listing) => ({
            ...listing,
            is_user_applied: false,
        }));
    }, [appState.isAuthenticated, authListings, noAuthListings]);

    return (
        <div className="container mx-auto px-6 pb-8 md:px-16">
            <div className="flex flex-col justify-between gap-4 py-8 md:flex-row">
                <TableSearchField onChange={onSearchChange} placeholder={i18n("listings.table.search")} />
                <div className="-order-1 w-full max-w-sm md:order-2 md:max-w-[250px]">
                    <Select
                        clearable={false}
                        onChange={onCityChange}
                        options={availableCities}
                        value={selectedCity}
                        fullWidth
                    />
                </div>
            </div>
            <ListingsTableBody
                listings={listings}
                isLoading={[noAuthLoadStatus, authLoadStatus].includes(FetchStatus.Loading)}
            />
        </div>
    );
};

export default ListingsTable;
