import {useCallback} from "react";
import {generatePath, useParams} from "react-router-dom";

import {getHomePathByCity} from "common/util/getHomePathByCity";
import {AppRoute} from "constants/routes";

export const useAbsolutePath = () => {
    const params = useParams();

    const homePath = getHomePathByCity(params.city || "");

    const getHomePath = useCallback(() => {
        return homePath;
    }, [homePath]);

    const getListingPath = useCallback(
        (listingId: number) => {
            const listingPath = generatePath(AppRoute.LISTING, {listing_id: String(listingId)});
            return [homePath, listingPath].join("/");
        },
        [homePath],
    );

    const getCreateApplicationPath = useCallback(
        (listingId: number, applicationId: number) => {
            const listingPath = generatePath(AppRoute.LISTING, {listing_id: String(listingId)});
            const applicationCreatePath = generatePath(AppRoute.APPLICATION_CREATE, {
                application_id: String(applicationId),
            });
            return [homePath, listingPath, applicationCreatePath].join("/");
        },
        [homePath],
    );

    return {getHomePath, getListingPath, getCreateApplicationPath};
};
