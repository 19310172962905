import {useI18n} from "common/hooks/useI18n";

import {MobileViewheader} from "../components/MobileViewheader";
import {ApplicationWizardProps} from ".";
import WizardContent from "./WizardContent";

type WizardFormProps = Pick<
    ApplicationWizardProps,
    | "listingData"
    | "listingTranslations"
    | "listingId"
    | "applicationId"
    | "wizardFields"
    | "submit"
    | "isSubmitLoading"
    | "validatorResults"
    | "feedbackLink"
    | "onClose"
    | "shouldShowSubmittedSuccessContent"
>;

export function WizardForm({
    listingData,
    listingTranslations,
    listingId,
    applicationId,
    wizardFields,
    submit,
    isSubmitLoading,
    validatorResults,
    feedbackLink,
    onClose,
}: WizardFormProps) {
    const {i18n} = useI18n();
    return (
        <>
            <MobileViewheader
                listingData={listingData}
                listingTranslations={listingTranslations}
                listingId={listingId}
            />
            <div className="flex flex-col gap-2 pt-10 md:pt-0">
                <div className="bolt-font-heading-xs-accent md:bolt-font-heading-m-accent">
                    {i18n("application_wizard.rental_requirements.header")}
                </div>
                <p>{i18n("application_wizard.rental_requirements.description")}</p>
            </div>
            <WizardContent
                applicationId={applicationId}
                fields={wizardFields}
                onClose={onClose}
                submit={submit}
                feedbackLink={feedbackLink}
                isSubmitLoading={isSubmitLoading}
                validatorResults={validatorResults}
            />
        </>
    );
}
