import {useIntl} from "react-intl";

import {useI18n} from "common/hooks/useI18n";
import {formatCurrencyValueWithSymbol} from "@fleet/common/utils/currencyFormatUtils";

import {VehicleMarketplaceService} from "@bolteu/bolt-server-api-vehicle-marketplace";
import {Typography} from "@bolteu/kalep-react";

interface Props {
    listingData: VehicleMarketplaceService.ListingDataForApplication;
    listingDataTranslations: VehicleMarketplaceService.ListingFieldsTranslationsForApplication;
}

const Pricing = ({listingData, listingDataTranslations}: Props) => {
    const intl = useIntl();
    const {i18n} = useI18n();

    if (!listingDataTranslations.pricing_fields) {
        return null;
    }

    const offerTypeText = i18n(`application_wizard.listing_data.pricing.${listingData.offer_type}`);
    const offerPeriodText = listingDataTranslations.pricing_fields.values.offer_period?.value;
    const depositSumText = listingDataTranslations.pricing_fields.values.deposit_sum?.key;

    return (
        <div className="flex flex-col gap-4">
            <Typography variant="body-l-accent" fontWeight="semibold">
                {i18n("application_wizard.listing_data.pricing.rental_price")}
            </Typography>
            <PaymentOptions>
                <PaymentOptionItem
                    title={formatCurrencyValueWithSymbol(intl, listingData.offer_price, listingData.currency_symbol)}
                    subtitle={offerPeriodText}
                />
                <PaymentOptionItem title={offerTypeText} subtitle={i18n("listing.ask_owner_for_details")} />
                {listingData.pricing_fields.deposit_sum && (
                    <PaymentOptionItem
                        title={formatCurrencyValueWithSymbol(
                            intl,
                            listingData.pricing_fields.deposit_sum,
                            listingData.currency_symbol,
                        )}
                        subtitle={depositSumText}
                    />
                )}
            </PaymentOptions>
        </div>
    );
};

interface PaymentOptionItemProps {
    title: string;
    subtitle: string | undefined;
}

function PaymentOptionItem({title, subtitle}: PaymentOptionItemProps) {
    return (
        <div className="py-2">
            <Typography variant="body-m-regular">{title}</Typography>
            <span className="text-secondary text-sm">{subtitle}</span>
        </div>
    );
}

function PaymentOptions({children}: {children: React.ReactNode}) {
    return (
        <div className="flex flex-col [&>div:last-child]:border-0 [&>*]:border-b [&>*]:border-b-neutral-400">
            {children}
        </div>
    );
}

export default Pricing;
