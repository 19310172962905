import {Page} from "common/components/page/Page";
import {ValidatorResult} from "@fleet/common/hooks/useFetch";

import {FleetOwnerRegistrationService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {VehicleMarketplaceService} from "@bolteu/bolt-server-api-vehicle-marketplace";

import {VehicleDetailsSidebar} from "../components/VehicleDetailsSidebar";
import {SubmittedWizardContent} from "./SubmittedWizardContent";
import {WizardForm} from "./WizardForm";

export interface ApplicationWizardProps {
    applicationId: number;
    listingData: VehicleMarketplaceService.ListingDataForApplication;
    listingTranslations: VehicleMarketplaceService.ListingFieldsTranslationsForApplication;
    wizardFields: FleetOwnerRegistrationService.FormField[];
    feedbackLink?: string;
    listingImageUrl?: string;
    isSubmitLoading: boolean;
    validatorResults: ValidatorResult[];
    shouldShowSubmittedSuccessContent: boolean;
    companyDetails?: VehicleMarketplaceService.CompanyDetailsWithContacts;
    listingId: number;
    onClose: () => void;
    submit: ((request: VehicleMarketplaceService.UpdateApplicationRequest) => Promise<void>) | null;
}

export const ApplicationWizard = ({
    listingData,
    listingTranslations,
    wizardFields,
    applicationId,
    feedbackLink,
    isSubmitLoading,
    validatorResults,
    shouldShowSubmittedSuccessContent,
    companyDetails,
    listingId,
    listingImageUrl,
    onClose,
    submit,
}: ApplicationWizardProps) => {
    return (
        <Page>
            <div className="flex flex-col gap-6 break-words md:grid md:grid-cols-12 md:pt-20">
                <div className="hidden h-full md:col-span-4 md:block">
                    <VehicleDetailsSidebar
                        listingData={listingData}
                        listingTranslations={listingTranslations}
                        listingImageUrl={listingImageUrl}
                    />
                </div>
                <div className="flex flex-col gap-4 md:col-span-full md:col-start-6">
                    {shouldShowSubmittedSuccessContent ? (
                        <SubmittedWizardContent
                            onClose={onClose}
                            feedbackLink={feedbackLink}
                            companyDetails={companyDetails}
                        />
                    ) : (
                        <WizardForm
                            listingData={listingData}
                            listingTranslations={listingTranslations}
                            listingId={listingId}
                            applicationId={applicationId}
                            wizardFields={wizardFields}
                            submit={submit}
                            isSubmitLoading={isSubmitLoading}
                            validatorResults={validatorResults}
                            feedbackLink={feedbackLink}
                            shouldShowSubmittedSuccessContent={shouldShowSubmittedSuccessContent}
                            onClose={onClose}
                        />
                    )}
                </div>
            </div>
        </Page>
    );
};
