import {useI18n} from "common/hooks/useI18n";

import {Link} from "@bolteu/kalep-react";

interface Props {
    feedbackLink?: string;
}

export const FeedbackLink = ({feedbackLink}: Props) => {
    const {i18n} = useI18n();

    if (!feedbackLink) {
        return null;
    }

    return (
        <p>
            {i18n("application_wizard.rental_requirements.submit_feedback_note.text", {
                feedbackLink: (
                    <Link href={feedbackLink} target="_blank" overrideClassName="font-semibold">
                        {i18n("application_wizard.rental_requirements.submit_feedback_note.feedback_link_text")}
                    </Link>
                ),
            })}
        </p>
    );
};
