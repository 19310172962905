import {useContext, useEffect, useMemo, useState} from "react";

import {Api, ApiContextProvider} from "common/services/api/apiProvider";
import {isApplicationFormValidationError} from "common/util/isErrorType";
import {TranslationKeys} from "config/translations";
import {FetchStatus, Files, UseFetch, ValidatorResult} from "@fleet/common/hooks/useFetch";
import {NotificationContext, NotificationType} from "@fleet/common/services/notificationProvider";

import {useI18n} from "./useI18n";
import {useValidationErrorMessages} from "./useValidationErrorMessages";

export interface FetchError {
    message: string;
    validationErrors: ValidatorResult[];
}

export {FetchStatus};

export type FetchFunction<T, R> = (api: Api, body: T, files?: Files) => Promise<R>;
export type FetchFunctionNoAuth<T, R> = (body: T, files?: Files) => Promise<R>;

export function useFetch<T, R, F>(fetchFunction: FetchFunction<T, R>): UseFetch<T, R> {
    const {i18n} = useI18n();
    const api = useContext(ApiContextProvider);
    const {setNotification} = useContext(NotificationContext);

    const {getDynamicFormValidationErrorMessages} = useValidationErrorMessages();

    const [errorMessage, setErrorMessage] = useState("");
    const [validationErrors, setValidationErrors] = useState<ValidatorResult[]>([]);

    const error = useMemo<FetchError>(
        () => ({message: errorMessage, validationErrors}),
        [errorMessage, validationErrors],
    );
    const [status, setStatus] = useState(FetchStatus.Init);
    const [data, setData] = useState<R | null>(null);

    useEffect(() => {
        return () => {
            setStatus(FetchStatus.Init);
            setData(null);
            setErrorMessage("");
            setValidationErrors([]);
        };
    }, []);

    const fetch = useMemo(() => {
        if (!api) {
            return null;
        }

        return async (body: T, files?: F) => {
            setStatus(FetchStatus.Loading);

            try {
                let res: R;
                if (files) {
                    res = await fetchFunction(api, body, files);
                } else {
                    res = await fetchFunction(api, body);
                }
                setData(res);
                setStatus(FetchStatus.Success);
                setErrorMessage("");
                setValidationErrors([]);
            } catch (e) {
                setStatus(FetchStatus.Error);
                if (isApplicationFormValidationError(e)) {
                    setValidationErrors(getDynamicFormValidationErrorMessages(e.response.data));
                } else if (e instanceof Error) {
                    setErrorMessage(e.message);
                    setValidationErrors([]);
                }
            }
        };
    }, [api, fetchFunction, getDynamicFormValidationErrorMessages]);

    useEffect(() => {
        if (errorMessage) {
            setNotification({
                type: NotificationType.ERROR,
                text: i18n(`api.error.${errorMessage}` as TranslationKeys, undefined, "api.default_error"),
                timeout: 6000,
            });
            setErrorMessage("");
        }
    }, [errorMessage, i18n, setNotification]);

    return {status, data, fetch, error} as UseFetch<T, R>;
}

export function useFetchNoAuth<T, R, F>(fetchFunction: FetchFunctionNoAuth<T, R>): UseFetch<T, R> {
    const {i18n} = useI18n();
    const {setNotification} = useContext(NotificationContext);
    const [errorMessage, setErrorMessage] = useState("");

    const error = useMemo<FetchError>(() => ({message: errorMessage, validationErrors: []}), [errorMessage]);
    const [status, setStatus] = useState(FetchStatus.Init);
    const [data, setData] = useState<R | null>(null);

    useEffect(() => {
        return () => {
            setStatus(FetchStatus.Init);
            setData(null);
            setErrorMessage("");
        };
    }, []);

    const fetch = useMemo(() => {
        return async (body: T, files?: F) => {
            setStatus(FetchStatus.Loading);

            try {
                let res: R;
                if (files) {
                    res = await fetchFunction(body, files);
                } else {
                    res = await fetchFunction(body);
                }
                setData(res);
                setStatus(FetchStatus.Success);
                setErrorMessage("");
            } catch (e) {
                setStatus(FetchStatus.Error);
                if (e instanceof Error) {
                    setErrorMessage(e.message);
                }
            }
        };
    }, [fetchFunction]);

    useEffect(() => {
        if (errorMessage) {
            setNotification({
                type: NotificationType.ERROR,
                text: i18n(`api.error.${errorMessage}` as TranslationKeys, undefined, "api.default_error"),
                timeout: 6000,
            });
            setErrorMessage("");
        }
    }, [errorMessage, i18n, setNotification]);

    return {status, data, fetch, error} as UseFetch<T, R>;
}
