import {useCallback} from "react";

import {TranslationKeys} from "config/translations";
import {FormValidationError} from "@fleet/common/errors/formValidationError";
import {FormValidatorResult} from "@fleet/common/hooks/useFetch";

import {useI18n} from "./useI18n";

interface ValidatorMessage {
    property?: string | number;
    message: string;
}

export const useValidationErrorMessages = () => {
    const {i18n} = useI18n();

    const getValidationMessage = useCallback(
        (message: ValidatorMessage) => {
            return i18n(`api.error.validation.${message.message}` as TranslationKeys, undefined, "api.default_error");
        },
        [i18n],
    );
    const getDynamicFormValidationErrorMessages = useCallback(
        (error: FormValidationError) => {
            return error.errors.map((validatorResult: FormValidatorResult) => {
                return {
                    property: validatorResult.attribute,
                    error: getValidationMessage(validatorResult),
                };
            });
        },
        [getValidationMessage],
    );

    return {getDynamicFormValidationErrorMessages};
};
