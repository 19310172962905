import {useIntl} from "react-intl";

import {useI18n} from "common/hooks/useI18n";
import {formatCurrencyValueWithSymbol} from "@fleet/common/utils/currencyFormatUtils";

import {VehicleMarketplaceService} from "@bolteu/bolt-server-api-vehicle-marketplace";

import ViewVehicleDetails from "./ViewVehicleDetails";

interface MobileViewHeaderProps {
    listingData: VehicleMarketplaceService.ListingDataForApplication;
    listingTranslations: VehicleMarketplaceService.ListingFieldsTranslationsForApplication;
    listingId: number;
}

export function MobileViewheader({listingData, listingTranslations, listingId}: MobileViewHeaderProps) {
    const {i18n} = useI18n();
    const intl = useIntl();
    return (
        <>
            <h3 className="bolt-font-heading-s-accent pb-3 font-semibold md:hidden">
                {i18n("application_wizard.header")}
            </h3>
            <div className="bg-layer-surface flex w-full flex-col gap-1 rounded-lg p-4 md:hidden">
                <p className="bolt-font-body-l-accent">
                    {listingData.vehicle_model_name}, {listingData.vehicle_years}
                </p>
                <div>
                    <span>
                        {formatCurrencyValueWithSymbol(intl, listingData.offer_price, listingData.currency_symbol)}
                    </span>
                    <span> / </span>
                    <span>{listingTranslations.pricing_fields?.values.offer_period?.value}</span>
                </div>
                <ViewVehicleDetails listingId={Number(listingId)} />
            </div>
        </>
    );
}
