import {useI18n} from "common/hooks/useI18n";

import {VehicleMarketplaceService} from "@bolteu/bolt-server-api-vehicle-marketplace";
import {Button, Link} from "@bolteu/kalep-react";
import {Business, Call, CheckCircle, Mail} from "@bolteu/kalep-react-icons";

import {FeedbackLink} from "../components/FeedbackLink";

interface Props {
    onClose: () => void;
    feedbackLink?: string;
    companyDetails?: VehicleMarketplaceService.CompanyDetailsWithContacts;
}

export const SubmittedWizardContent = ({onClose, feedbackLink, companyDetails}: Props) => {
    const {i18n} = useI18n();

    return (
        <div className="flex flex-col gap-8 px-6 py-2">
            <div className="flex flex-col justify-center gap-8 text-center md:items-start md:text-left">
                <div className="flex flex-col items-center gap-4 md:flex-row md:items-start">
                    <CheckCircle width={40} height={40} className="text-action-secondary" />
                    <div className="bolt-font-heading-m-accent">
                        {i18n("application_wizard.successful_request_sent_section.header")}
                    </div>
                </div>
                <div>
                    <p>{i18n("application_wizard.successful_request_sent_section.description_confirmation")}</p>
                    <p>{i18n("application_wizard.successful_request_sent_section.description_waiting_time")}</p>
                </div>
            </div>
            {companyDetails && (
                <div className="flex flex-col gap-9">
                    <p className="bolt-font-body-s-accent">
                        {i18n("application_wizard.successful_request_sent_section.contact_details")}
                    </p>
                    <div className="flex flex-col gap-6">
                        {companyDetails.name && (
                            <div className="flex gap-3">
                                <Business />
                                {companyDetails.name}
                            </div>
                        )}
                        {companyDetails.contacts?.email && (
                            <div className="flex gap-3">
                                <Mail />
                                <Link href={`mailto:${companyDetails.contacts.email}`}>
                                    {companyDetails.contacts.email}
                                </Link>
                            </div>
                        )}
                        {companyDetails.contacts?.phone && (
                            <div className="flex gap-3">
                                <Call />
                                <Link href={`tel:${companyDetails.contacts.phone}`}>
                                    {companyDetails.contacts.phone}
                                </Link>
                            </div>
                        )}
                    </div>
                </div>
            )}
            <div className="bolt-font-body-s-regular text-secondary">
                <FeedbackLink feedbackLink={feedbackLink} />
            </div>
            <div className="flex justify-center md:justify-start">
                <Button overrideClassName="flex-1 md:flex-none" variant="secondary" onClick={onClose}>
                    {i18n("application_wizard.rental_requirements.back_button_text")}
                </Button>
            </div>
        </div>
    );
};
