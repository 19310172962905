import {useCallback, useState} from "react";

import {useI18n} from "common/hooks/useI18n";
import {
    ConfirmActionDialog,
    ConfirmActionDialogVariant,
} from "@fleet/common/components/confirm_action_dialog/ConfirmActionDialog";

import {VehicleMarketplaceService} from "@bolteu/bolt-server-api-vehicle-marketplace";
import {Alert, Typography} from "@bolteu/kalep-react";

import {useApplicationAlert} from "../hooks/useApplicationAlert";

interface Props {
    application: VehicleMarketplaceService.ApplicationDetails | null;
    vehicleName: string;
    onApplicationDeactivated: () => void;
    isLoading: boolean;
}

export const ApplicationAlert = ({application, vehicleName, onApplicationDeactivated, isLoading}: Props) => {
    const {i18n} = useI18n();

    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const onCancelRequest = useCallback(() => {
        setIsDialogOpen(true);
    }, []);

    const onDialogClose = useCallback(() => {
        setIsDialogOpen(false);
    }, []);

    const onDialogConfirm = useCallback(() => {
        onApplicationDeactivated();
        setIsDialogOpen(false);
    }, [onApplicationDeactivated]);

    const data = useApplicationAlert({
        application,
        vehicleName,
        onCancelRequest,
    });

    if (!data) {
        return null;
    }

    const {severity, title, description, action} = data;
    return (
        <>
            <Alert
                severity={severity}
                title={title}
                action={action}
                overrideClassName="[&>div>div[role='button']]:!text-sm [&>div>div[role='button']]:!mt-2"
            >
                <Typography color="primary" fontSize="text-sm">
                    {description}
                </Typography>
            </Alert>
            <ConfirmActionDialog
                titleText={i18n("listing.alert.cancel_request_confirmation_dialog.title")}
                descriptionText={i18n("listing.alert.cancel_request_confirmation_dialog.description")}
                confirmButtonText={i18n("listing.alert.cancel_request_confirmation_dialog.confirm_button_text")}
                cancelButtonText={i18n("listing.alert.cancel_request_confirmation_dialog.cancel_button_text")}
                isOpen={isDialogOpen}
                isLoading={isLoading}
                closeDialog={onDialogClose}
                confirmButtonAction={onDialogConfirm}
                confirmButtonVariant={ConfirmActionDialogVariant.Danger}
            />
        </>
    );
};
