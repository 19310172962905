import config from "config/index";

import {
    DriverAuthNoAuthService,
    HttpClientLibrary,
    VehicleMarketplaceNoAuthService,
} from "@bolteu/bolt-server-api-vehicle-marketplace";

import {browserInfo} from "../../../util/BrowserInfo";
import {localStorageService} from "../../LocalStorageService";

const httpClientConfig = {
    baseUrl: config.baseUrl,
};

const vehicleMarketplaceHttpClientInitConfig = {
    standardRequestStrategy: {
        getStandardRequest: () => {
            const visitorId = browserInfo.getVisitorId();
            return {
                version: config.version,
                brand: config.appBrand as unknown as VehicleMarketplaceNoAuthService.BrandEnum,
                language: localStorageService.getLanguageWithDefaultLocale(),
                visitor_id: visitorId,
                page_url: window.location.href,
            };
        },
    },
};

const driverAuthHttpClientInitConfig = {
    standardRequestStrategy: {
        getStandardRequest: () => {
            const deviceInfo = browserInfo.getDeviceInfo();
            return {
                language: localStorageService.getLanguageWithDefaultLocale(),
                version: config.version,
                brand: config.appBrand as unknown as DriverAuthNoAuthService.BrandEnum,
                deviceId: deviceInfo.device_uuid,
                device_name: deviceInfo.device_name,
                device_os_version: deviceInfo.device_os_version,
            };
        },
    },
};

const vehicleMarketPlaceNoAuthHttpClient =
    new HttpClientLibrary.HttpClientBoltServer<VehicleMarketplaceNoAuthService.HttpClientConstraints>(httpClientConfig);
vehicleMarketPlaceNoAuthHttpClient.init(vehicleMarketplaceHttpClientInitConfig);
const vehicleMarketplaceNoAuthApiClient: VehicleMarketplaceNoAuthService.ApiClient =
    new VehicleMarketplaceNoAuthService.ApiClient({
        httpClient: vehicleMarketPlaceNoAuthHttpClient,
    });

const driverAuthNoAuthHttpClient =
    new HttpClientLibrary.HttpClientBoltServer<DriverAuthNoAuthService.HttpClientConstraints>(httpClientConfig);
driverAuthNoAuthHttpClient.init(driverAuthHttpClientInitConfig);
const driverAuthNoAuthApiClient: DriverAuthNoAuthService.ApiClient = new DriverAuthNoAuthService.ApiClient({
    httpClient: driverAuthNoAuthHttpClient,
});

export {
    vehicleMarketplaceNoAuthApiClient as VehicleMarketplaceNoAuthApiClient,
    driverAuthNoAuthApiClient as DriverAuthNoAuthApiClient,
};
