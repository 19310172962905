import {createContext, useContext, useState} from "react";

interface HeaderContextType {
    isVisible: boolean;
    setIsVisible: (isVisible: boolean) => void;
}

export const HeaderContext = createContext<HeaderContextType>({
    isVisible: true,
    setIsVisible: () => {},
});

export const HeaderContextProvider = ({children}: {children: React.ReactNode}) => {
    const [isVisible, setIsVisible] = useState(true);

    return <HeaderContext.Provider value={{isVisible, setIsVisible}}>{children}</HeaderContext.Provider>;
};

export const useHeaderContext = () => {
    const context = useContext(HeaderContext);
    if (!context) {
        throw new Error("useHeaderContext must be used within a HeaderContextProvider");
    }
    return context;
};
