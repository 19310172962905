import {CommonLocalStorageService} from "@fleet/common/services/CommonLocalStorageService";

const portalPrefix = "VM_";

export enum LocalStorageKeys {
    LOCALE = "locale",
    IP_LOCATION = "ip_location_data",
    REFRESH_TOKEN = "refresh_token",
    VISITOR_ID = "visitor_id",
}

class LocalStorageService extends CommonLocalStorageService {
    constructor(keyPrefix: string) {
        super(keyPrefix);
    }

    public getLanguageWithDefaultLocale(defaultLocale: string = "en-us") {
        const language = this.getItem(LocalStorageKeys.LOCALE);

        if (!language) {
            return defaultLocale;
        }

        return JSON.parse(language);
    }
}

export const localStorageService = new LocalStorageService(portalPrefix);
