import {useI18n} from "common/hooks/useI18n";

import {Typography} from "@bolteu/kalep-react";

const Footer = () => {
    const year = new Date().getFullYear();
    const {i18n} = useI18n();
    return (
        <footer className="flex h-20 items-center justify-end py-8 px-16 shadow">
            <Typography variant="body-m-regular">{i18n("site.copyright", {year})}</Typography>
        </footer>
    );
};

export default Footer;
